.flashMsgContainer{
    position: fixed;
    top: 50px;
    right: 10px;
    background-color: #ECF9F8;
    display: flex;
    padding: 20px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
    z-index: 99999999;
}

.flashMsgContainer > div{
    width: 40px;
    height: 40px;
    background-color: #1BBAAC;
    margin: 0 10px 0 0;
    border-radius: 10px;
    position: relative;
}
.flashMsgContainer > div > svg{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.flashMsgContainer > p{
    width: 250px;
    text-align: left;
    margin: 0 10px 0 0;
}
.flashMsgContainer > button{
    background-color: transparent;
    border: none;
}

