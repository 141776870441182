.NUinput{
    border-radius: 20.5px !important;
    border: solid 0.5px #e0e0e6 !important;
    font-size: 14px !important;
    height: 40px !important;
    -webkit-border-radius: 20.5px !important;
    -moz-border-radius: 20.5px !important;
    -ms-border-radius: 20.5px !important;
    -o-border-radius: 20.5px !important;
}
.textarea{
    height: 100%;
    width: 100%;
    text-indent: 10px;
    border: 0;
    outline: none;
    min-height: 170px;
    padding: 7px;
}
.input{
    height: 100%;
    width: 100%;
    text-indent: 10px;
    border: 0;
    outline: none;
}
.error-area {
    display: flex;
    align-items: center;
    margin-top: 5px;
    align-self: flex-start;
    /* margin-left: 14px; */
}
.error-message {
    color: #da3025;
    font-size: 80%;
}

.input:focus{
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
}
.password__show{
    position: absolute;
    z-index: 2;
    float: right;
    top: 2px;
    right: 0px;
}