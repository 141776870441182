.DAresearchResultCard{
    background-color: white;
    padding: 5px;
    border-radius: 11px;
    width: 97%;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px, rgb(0 0 0 / 19%) 0px 6px 20px 0px;
    text-align: left;
    margin: 3% 1% 60px 2%;
}

.DAresearchResultCard > h1{
    font-size: 20px;
    padding: 0px 0px 0px 10px;
}

.paginationCountainer{
    display: flex;
    float: right;
}

.paginationRow{
    text-align: right;
    background-color: white !important;
}

.paginationCol{
    border: 1px solid transparent !important;
}

.tableBtnBack{
    border: none;
    color: #5063B9;
    background-color: #5063b92b;
    padding: 0px 10px;
    border-radius: 5px;
}

.tableBtnBack > svg{
    height: 40px;
    width: 20px;
}

.tableNbr{
    display: inline-block;
    text-align: center;
    width: 40px;
    margin: 0 10px;
    font-size: 17px;
    background-color: #dce0ee;
    height: 39px;
    padding: 6% 0 0 0;
    color: #5063B9;
    border-radius: 5px;
}

.tableBtnForward{
    border: none;
    color: #5063B9;
    background-color: #5063b92b;
    padding: 0px 10px;
    border-radius: 5px;
}

.tableBtnForward > svg{
    height: 40px;
    width: 20px;
}

button:disabled,
button[disabled]{
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
}
.tableFixHead { 
    overflow-y: auto; 
    max-height: 600px; 
}
.tableFixHead::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .tableFixHead {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.tableFixHead table  { border-collapse: collapse; width: 100%; }
.tableFixHead th, td { padding: 8px 16px; }
.tableFixHead thead th {
     position: sticky; top: 0; background:#EBEBED;
     cursor: pointer;
     user-select: none;
    }
.tableFixHead .table-bordered {
    border: 0 solid #ddd !important;
}
.tableFixHead  .table-bordered>thead>tr>th {
    border-bottom-width: 0!important;
}
/* .tableFixHead{
    overflow: auto;
     height: 200px;
}
.tableFixHead thead th { 
    position: sticky; 
    top: 0; 
    z-index: 1;
    background-color: #EBEBED;
 } */


 .Th{
    position: relative;
    cursor: pointer;
    padding-right: 5px;
  }

  .table-order-container{
    display: flex;
    flex-direction: column;
    width: 26px;
    height: 26px;
    border-radius: 8px;
    background-color: rgba(185, 185, 185, 0.08);
    position: absolute;
    right: 0;
    top:50%;
    transform: translateY(-50%);
  }
  .table-order-container a {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 50%;
    color: #9d9d9d;
  }  

  .table-th-is-active{
    border-bottom-color: #4caace!important;
  }
  .table-order-is-active{
    background-color: rgba(0, 174, 212, 0.04)!important;
  }
  
  .table-th-is-active a{
    color:#02829f !important;
  }
  .DAresearchResultCard-title{
      display:  flex;
      align-items: center;
  }
  .no-result-container{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 350px;
      margin-bottom: 20px;
  }