
.basic-select .basic-select__control{
    padding: 5px 0px 7px 0px !important;
    background: #FFFFFF!important;
    border: 1px solid #E0E0E6!important;
    border-radius: 25px!important;
}
.basic-select .basic-select__control--is-focused{
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
}

.basic-select .basic-select__input > input{
    padding-left: 5px!important;
}
.basic-select .basic-select__placeholder{
    margin-left: 6px!important;
}

.basic-select .basic-select__single-value{
    margin-left: 6px!important;
}