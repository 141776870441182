.CategoriesSelected{
    padding: 5px;
    background: #FFFFFF no-repeat padding-box;
    border: 1px solid #E0E0E6;
    border-radius: 25px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;

}

.CatElement{
    margin: 3px 2px;
    padding: 0px 9px;
    color: #fff;
    background-color: #5063B9;
    width: fit-content;
    display: inline-block;
    border-radius: 20px;
    font-family: 'Poppins';
}
.CatElement button{
    background-color: transparent;
    border: none;
    margin: 0 0 0 5px;
}

#inputNewCat{
    display: flex;
    flex: 1;
    min-width: 135px;
    border: none;
    background-color: transparent;
    outline: none;
}
.basic-multi-select .select__multi-value{
    margin: 3px 2px !important;
    padding: 5px 9px !important;
    background-color: #5063B9 !important;
    width: fit-content !important;
    display: flex !important;
    align-items: center;
    border-radius: 20px !important;
    
}
.basic-multi-select .select__multi-value__label{
    color: #fff !important;
    font-family: 'Poppins' !important;
}

.basic-multi-select .select__multi-value__remove{
    color: #fff;
}

.basic-multi-select .select__multi-value__remove:hover{
    color: #5063B9!important;
    background-color: #fff!important;
    border-radius: 20px;
}
.basic-multi-select .select__control{
    padding: 5px 0px 7px 0px !important;
    background: #FFFFFF!important;
    border: 1px solid #E0E0E6!important;
    border-radius: 25px!important;
}
.basic-multi-select .select__control--is-focused{
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
}

.basic-multi-select .select__input > input{
    padding-left: 5px!important;
}
.basic-multi-select .select__placeholder{
    margin-left: 6px!important;
}

.SelectItemsInputComparison__error{
    color: rgb(255, 80, 80);
}
.SelectItemsInputComparison__container{
    position: relative;
}
.SelectItemsInputComparison__clear-btn{
    position: absolute;
    top:0;
    right: 0;
    background-color: transparent;
    border-width: 0;
    outline: none;
}
.SelectItemsInputComparison__clear-btn > svg{
   transition: all .2s;
   color: rgb(255, 80, 80,0.7);
}
.SelectItemsInputComparison__clear-btn:hover > svg{
    color: rgb(255, 80, 80);
 }
 .select__menu{
     z-index: 999999 !important;
 }