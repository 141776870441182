.MapMarker__container{
    background-color: #5063b9;
    border-radius: 12px;
    padding:3px 3px 3px 3px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    min-width: 40px !important;
}
.MapMarker__bottom-flesh{
    height: 7px;
    width: 7px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-2.5);
    background-color: #5063b9;
}