.DateRangePicker .react-daterange-picker__wrapper{
    border: solid 0.5px #e0e0e6 !important;
    border-radius: 20.5px !important;
    padding: 12px 12px!important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-weight: 400;
}
.DateRangePicker .react-daterange-picker__inputGroup__input{
    outline: none;
}
.DateRangePicker .react-calendar__tile--active{
    /* border-radius: 11px!important; */
}
.DateRangePicker .react-calendar__tile--now{
    background: #6F84F0!important;
    color: #fff!important;
}
.DateRangePicker .react-calendar__month-view__days__day--weekend{

}
.DateRangePicker .react-calendar button{
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-weight: 400!important;
}
.DateRangePicker .react-calendar{
    border: solid 0.5px #e0e0e6 !important;
    border-radius: 20.5px !important;
    overflow: hidden;
}
.DateRangePicker .react-calendar__navigation__label{
    text-transform: capitalize!important;
}