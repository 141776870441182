

.searchForm .col{
    padding: 10px 20px;
}
.searchForm .col select{
    height: 55px;
    border-radius: 30px;
    border: 1px solid #E0E0E6;
    font-family: 'Poppins';
}
.CategoriesSelected{
    padding: 5px;
    background: #FFFFFF no-repeat padding-box;
    border: 1px solid #E0E0E6;
    border-radius: 25px;
}
.CatElement{
    margin: 3px 2px;
    padding: 0px 9px;
    color: #fff;
    background-color: #5063B9;
    width: fit-content;
    display: inline-block;
    border-radius: 20px;
    font-family: 'Poppins';
}
.CatElement button{
    background-color: transparent;
    border: none;
    margin: 0 0 0 5px;
}

#inputNewCat{
    width: 135px;
    border: none;
    background-color: transparent;
}

.boolCountaner{
    width: 360px;
    height: 60px;
    display: flex;
    border: 1px solid #5063B9;
    border-radius: 41px;
    position: relative;
}
.boolCountaner button{
    z-index: 4;
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: transparent;
    border: none;
    font-family: 'Poppins';
    font-size: 14.4px;
}

.backActiv{
    background-color: #5063B9;
    width: 50%;
    height: 95%;
    margin: 2px;
    position: absolute;
    border-radius: 45px;
}

#submit{
    width: 207.2px;
    height:60.8px;
    border-radius: 40px;
    border: none;
    background-color: #5063B9;
    color: #fff;
    font-size: 17.6px;
    font-family: 'Poppins';
    float: right;
}
#saveSearch{
    width: 319px ;
    height:60.8px;
    border-radius: 40px;
    border: none;
    background-color: #fff;
    border: 1px solid #5063B9;
    color: #5063B9;
    font-size: 17.6px;
    font-family: 'Poppins';
    float: right;
    margin-right: 10px;
}

svg.closeBtnSvg path {
    stroke: red;
}

.SearchForm__add-comparison-btn-container{
    width: 100%;
    text-align: center;
}
.SearchForm__add-comparison-btn{
    background-color: transparent;
    border-width: 0;
    outline: none;
    color: #5063B9;
}
