/* The container */
.Checkbox__container {
    display: flex;
    min-height: 13px;
    align-items: center;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    font-size: 14px;
    font-family: inherit;
    font-weight: 400!important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 15px;
  }
  .Checkbox__label-text{
    display: inline-block;
    font-weight: 700;
    /* margin-top: 5px; */
  }
  /* Hide the browser's default checkbox */
  .Checkbox__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .Checkbox__checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #e0e0e6;
    border-radius: 2px;
  }
  
  /* On mouse-over, add a grey background color */
  .Checkbox__container:hover input ~ .Checkbox__checkmark {
    background-color: transparent;
  }
  
  /* When the checkbox is checked, add a blue background */
  .Checkbox__container input:checked ~ .Checkbox__checkmark {
    /* background-color: #2196F3; */
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .Checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .Checkbox__container input:checked ~ .Checkbox__checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .Checkbox__container .Checkbox__checkmark:after {
    left: 5px;
    top: 3px;
    width: 6px;
    height: 8px;
    border: solid #202020;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  