.usersCard{
    width: 90%;
    background-color: white;
    margin: auto;
    margin-top: 20px;
    border-radius: 11px;
    box-shadow: 0 1.5px 10px 0 rgb(0 0 0 / 16%);
    max-width: 1440px;
}

.usersTitleCountainer{
    display: inline-block;
    width: 55%;
    text-align: left;
    padding: 0 0 0 20px;
}
.NewUserModal {
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: visible;
    border-radius: 11px;
    outline: none;
    padding: 20px;
    width: 1040px;
    height: fit-content;
    margin: auto;
}

.NewUserModal .NUsubmit{
    width: 200px;
    height: 56px;
    background-color: #5063B9;
    border: none;
    border-radius: 44px;
    font: normal normal normal 17.6px/33px Poppins;
}

.NUinput{
    border-radius: 20.5px !important;
    border: solid 0.5px #e0e0e6 !important;
    font-size: 16px !important;
    height: 40px !important;
    -webkit-border-radius: 20.5px !important;
    -moz-border-radius: 20.5px !important;
    -ms-border-radius: 20.5px !important;
    -o-border-radius: 20.5px !important;
}

.password__show{
    position: absolute;
    z-index: 2;
    float: right;
    top: 33px;
    right: 30px;
}

.clickable{
    transition: transform .1s;
    -webkit-transition: transform .1s;
    -moz-transition: transform .1s;
    -ms-transition: transform .1s;
    -o-transition: transform .1s;
}
.clickable:hover{
    -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
  cursor: pointer;
}

.NUsubmit{
    float: right;
    font-size: 17px;
    font-weight: bold;
    padding: 11px 40px;
    border-radius: 25px;
    background-color: #5063b9;
    border: none;
}

.NUsubmit:hover{
    background-color: #768cee;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #5063b917;
}



.usersTable  tbody  td,
.usersTable  thead  th{
    padding: 20px 0 20px 20px !important;
}


.userActif{
    color: #35c4d4;
    background-color: #35c4d429;
    padding: 5px;
    width: 60px;
    text-align: center;
    border-radius: 5px;
}

.userInactif{
    color: #FF5050;
    background-color: #ff505026;
    padding: 5px;
    width: 60px;
    text-align: center;
    border-radius: 5px;
}
.editUserTable{ 
    display: inline-block;
    margin: 0 10px 0 0;
    background-color: #d2eaf3;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 40px;
}

.deleteUserTable{
    margin: 0 10px 0 0;
    background-color: #ff505026;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 40px;
}
.enableUserTable{
    margin: 0 10px 0 0;
    background-color:rgba(74,210,149,0.2);
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 40px;
}
.DAusersCardTitle{
    font-size: 20px;
}
.NoResultCas__container{
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.NoResultCas__title{
    font-weight: 400;
    font-family: inherit;
    margin-bottom: 14px;
    margin-top: 8px;
    text-align: center;
}
.NoResultCas__desc{
    font-size: inherit;
    font-family: inherit;
    margin-bottom: 30px;
    max-width: 500px;
    text-align: center;
}
.NoResultCas__img{
    max-width: 100px;
}
.Loading__container{
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modalErrorMessage{
    color: #FF5050;
    font-family: 'Poppins', sans-serif;
}
.BtnUserModalCountainer{
    display: inline-block;
    text-align: right;
    padding-right: 1%;
}