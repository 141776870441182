


/*.st0:hover{
        fill: #919fdf;
}*/

.mapInfoBox{
    display: none;
    margin: 0px 0px 30px;
    width: 250px;
    opacity: 1;
    padding: 10px;
    height: auto !important;
    position: absolute;
}
.titleInfoBox{

    width: 80%;
    font-size: 15px;
}

.bullePer{
    transition: transform .1s;
    -webkit-transition: transform .1s;
    -moz-transition: transform .1s;
    -ms-transition: transform .1s;
    -o-transition: transform .1s;
}
.bullePer:hover{
    -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
  cursor: pointer;
}

.tltpTxt{
    position: sticky;
    margin: 5px 0 0 10px;
    color: white;
    font-weight: bold;
    font-size: 12px;
}

.svgTltp{
    position: absolute;
    width: 40px !important;
    z-index: 0;
    height: 30px;
}

.bullecover {
    float: left;
    width: 40px;
    height: 22px;
    z-index: 4;
    position: absolute;
    margin-top: 2px;
}
.DAmap__header-container{
    display: flex;
    align-items: center;
}
.DAmap__header-filters-container{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.btn{
    outline: none;
    background-color: transparent;
    border: 0;
}
.btn:focus{
    box-shadow: none !important;
}
.DAmap__header-filter-btn{
    border:2px solid  #337ab7!important;
    border-radius: 12px!important;
    padding:3px 3px 3px 3px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color:  #337ab7 !important;
    font-weight: bold!important;
    font-size: 12px!important;
    margin-left: 10px;
    transition: .3s;
}
.DAmap__header-filter-btn-active{
    background-color: #337ab7!important;
    color: #fff!important;
}