.GroupeItems__container{
    
}
.GroupeItems__btn{
    font-size: 20px !important;
}

.GroupeItems__modal{
    padding-bottom: 0 !important;
}
