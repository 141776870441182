.newSeartchBtn :hover{
    border-bottom: 1px solid;
}

.searchTitleCountainer{
    display: inline-block;
    width: 30%;
}

.BtnSearchModalCountainer{
    display: inline-block;
    width: 70%;
    text-align: right;
    padding-right: 1%;
}

.cateCountainer{
    width: 25%;
}

.cateCountainer > h3{
    font-size: 16px;
}
.SCateCountainer > h3{
    font-size: 16px;
}
.SSCateCountainer > h3{
    font-size: 16px;
}

.CateCountainerDesc{
    float: right;
    width: 75%;
}

.CateCountainerDesc > h3{
    font-size: 16px;
}

.SCateCountainer{
    float: left;
    width: 45%;
}

.SSCateCountainer{
    display: inline-block;
    width: auto;
}

.hrNoMargging{
    margin: 0%;
}

.SearchModal{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: visible;
    border-radius: 11px;
    outline: none;
    padding: 20px;
    width: 1100px;
    height: fit-content;
    max-height: 616px;
    margin: auto;
}
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.29) !important;
}
.SearchForm__container{
    position: relative;
    /* padding-bottom: 96px; */
    height: 100%;

}
.SearchForm__btns-container{
    /* position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; */
}
.headerSearchModal{
    display: flex;
}
.headerSearchModal > h2{
    width: 100%;
    font-size: 20px;
}
.headerSearchModal > button{
    border: none;
    background-color: #FF50501A;
    height: 50px;
    width: 50px;
    border-radius: 4.5px;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
  }
  .ComparisonDAsearch__container{
      padding-bottom: 15px !important;
      min-height: 362px;
  }

  .MarketViewDAsearch-container .react-daterange-picker{
     width: 100%;
  }
  .my-saved-search-item-container{
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    padding: 15px 26px 15px 32px;
    width: 100%;
    border-radius: 32px;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
    margin-bottom: 28px;
  }
  .my-saved-search-item-title{
    display: flex;
    flex: 1;
    align-items: center;
    font-weight: 400;
    color: #337ab7;
    cursor: pointer;
  }
  .my-saved-search-item-title:hover{
    color: #23527c;
    text-decoration: underline;
  }
  .my-saved-search{
      background-color: #fff;
      width: 100%;
      height: calc(100% - 52px - 5px);
      padding-top: 25px;
      max-height: 600px;
     overflow-y: scroll;
  }
  .BtnSearchModalCountainer-comparison{
    width: 70%;
  }
  .my-saved-search-item-container-comparison{
      width: fit-content;
  }
.my-saved-search-col{
    flex-grow:initial !important;
}