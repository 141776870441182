
.windowContainer{
    width: 100%;
    height: 97vh;
    position: relative;
}

.loginCountainer{
    width: 560px;
    padding: 55px 109px 20px;
    background-color: #F3F4F8;
    border-radius: 11px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Login__password-input-container{
    position: relative;
}
.loginCountainer button{
    margin: 30px 0;
    width: 100%;
    font-size: 17.6px;
    padding: 15px;
    border-radius: 35px;
    background-color: #6F84F0;
    border: none;
}

.loginCountainer h1{
    font-size: 26px;
    margin: 10px 0 25px;
}
.loginCountainer .form-group{
    text-align: left;
}
.loginCountainer label{
    margin: 15px 0px 12px;
    font-size: 15px;
    font-weight: 500;
}
.loginCountainer input {
    padding: 26px 12px;
    border-radius: 30px;
    padding-right: 15px;
}

.forgetPwd{
    text-align: right;
    padding-right: 25px;
}
.forgetPwd>a{
    color: #6F84F0;;
}
.clickable{
    transition: transform .1s;
    -webkit-transition: transform .1s;
    -moz-transition: transform .1s;
    -ms-transition: transform .1s;
    -o-transition: transform .1s;
}
.clickable:hover{
    -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
  cursor: pointer;
}
.loginCountainer .password__show{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
}
.loginCountainer .login__password-show{
    cursor: pointer;
    user-select: none;
}

.exclamationCount{
    display: inline-block;
    margin: 0 25px 0 0;
}
.flashMsgCount{
    padding: 25px;
    background-color: #FF50500F;
    border-radius: 8px;
    color: #FF5050;
    font-size: 17px;
}
.flashMsgCount p{
    display: inline-block;
}

.login-btn-loading{
    height: 54px;
}