.SavedSearchForm{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: visible;
    border-radius: 11px;
    outline: none;
    padding: 20px;
    width: 600px;
    height: 367px;
    height: fit-content;
    margin: auto;
}
.SavedSearch__form{
    /* padding-left: 50px;
    padding-right: 34px; */
    margin-bottom: 25px;
}
#searchLabelId{
    height: 50px !important;
}