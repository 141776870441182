
.navbar-expand{
    margin-bottom: 0;
}

.imageBrand{
    padding: 5px 0 0 15px;
}
.DAlogo{
    width: 40px;
}
.sobruseNavBrand{
    width: 40px;
}
.DAnavbar{
    background-color: white !important;
}

#DAwebTitle{
    margin-right: 10%;
}
.header__nav-link{
    display: flex;
    border-top: 3px solid transparent;
    margin-top: -6px;
    margin-bottom: -6px;
    height: 62px;
    justify-content: center;
    align-items: center;
    padding:0 8px;
    text-decoration: none !important;
    font-weight: normal;
}
.active{
    background-color: #5063B91A;
    color: #5063B9;
    position: relative;
    font-weight: 600;
    /* background-color:#5063B90B;
    border-top: 3px solid #5063B9!important; */

}
.active::before{
    content: " ";
    position: absolute;
    height: 3px;
    background-color: #5063B9;
    left: 0;
    right: 0;
    display: block;
    top: 0;
}

.header__nav-link:last-child{
    margin-right: 0;
}

/* text-decoration: none;
height: 100%;
background-color: red;
padding: 13px; */